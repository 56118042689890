#colored-text{
    color: #6022A6;
}

#colored-border{
    border-color: #6022A6;
}

#btn_back_to_top{
    background-color: #6022A6;
}